import React from "react";

import classNames from "classnames";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import "./recognition-sender.scss";

interface Props {
    className?: string;
}

const RecognitionSender = ({ className }: Props) => {
    const authenticatedUser = useAuthenticatedUser();

    const imageUrl = authenticatedUser.imageUrl || `${window.env.CDN_URL}/assets/avatars/avatar-7.png`;
    const fullName = authenticatedUser.fullName || "You";

    const classes = classNames(
        "recognition-sender",
        className
    );

    return (
        <div className={classes}>
            <img data-private className="recognition-sender__thumbnail" src={imageUrl} alt="" />
            <div className="recognition-sender__fullname">
                {fullName}
            </div>
        </div>
    );
};

export default RecognitionSender;