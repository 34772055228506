import React from "react";

import i18next from "i18next";

import {
    activityEndedAsync, getActivityAsync, loginAsync,
    saveCommunicationPreferencesAsync, saveMemberOnboardingAsync, savePasswordAsync, saveProfileAsync, type OnboardingContext,
    type OnboardingControllerSaveMemberOnboardingRequest,
    type OnboardingControllerSaveUserCommunicationPreferencesRequest
} from "@api/SignupApiClient";
import type SupportedDashboardLanguage from "@core/enums/SupportedDashboardLanguage";
import type SupportedSurveyLanguage from "@core/enums/SupportedSurveyLanguage";

import SignupContext from "./SignupContext";

interface Props {
    children: React.ReactNode;
}

const SignupContextProvider = ({ children }: Props) => {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [context, setContext] = React.useState<
    OnboardingContext & { isPasswordSet: boolean }
    >();

    const initializeSignupAsync = async() => {
        if (!isInitialized) {
            setIsInitialized(true);
            setIsLoading(true);

            const activityContext = await getActivityAsync();

            setContext({ ...activityContext, isPasswordSet: false });
            setIsLoading(false);
        }
    };

    const saveProfileAsyncCallback = async(
        surveyLanguage: SupportedSurveyLanguage,
        dashboardLanguage: SupportedDashboardLanguage,
        firstName: string,
        lastName: string
    ) => {
        await saveProfileAsync(
            surveyLanguage,
            dashboardLanguage,
            firstName,
            lastName
        );

        setContext(c => ({
            ...c!,
            surveyLanguage,
            dashboardLanguage,
            firstName,
            lastName
        }));
    };

    const saveCommunicationPreferencesAsyncCallback = async(
        data: OnboardingControllerSaveUserCommunicationPreferencesRequest
    ) => {
        await saveCommunicationPreferencesAsync(data);

        setContext(c => ({
            ...c!,
            ...data
        }));
    };

    const saveMemberOnboardingAsyncCallback = async(
        data: OnboardingControllerSaveMemberOnboardingRequest
    ) => {
        await saveMemberOnboardingAsync(data);

        setContext(c => ({
            ...c!,
            ...data,
            mobilePhone: data.mobilePhone ?? null,
            personalEmail: data.personalEmail ?? null
        }));
    };

    const savePasswordAsyncCallback = async(password: string) => {
        await savePasswordAsync(password);

        setContext(c => ({
            ...c!,
            isPasswordSet: true
        }));
    };

    const onSignupActivityEnded = async() => {
        await activityEndedAsync();
    };

    const loginAsyncCallback = async(userName: string, password: string) => {
        await loginAsync(userName, password);
    };

    return (
        <SignupContext.Provider
            value={{
                isInitialized,
                initializeSignupAsync,
                setSurveyLanguage: i18next.changeLanguage,
                saveProfileAsync: saveProfileAsyncCallback,
                saveCommunicationPreferencesAsync:
                    saveCommunicationPreferencesAsyncCallback,
                saveMemberOnboardingAsync: saveMemberOnboardingAsyncCallback,
                savePasswordAsync: savePasswordAsyncCallback,
                onSignupActivityEnded,
                loginAsync: loginAsyncCallback,
                ...(isLoading || !context
                    ? { isLoading: true, context: null }
                    : { isLoading: false, context })
            }}
        >
            {children}
        </SignupContext.Provider>
    );
};

export default SignupContextProvider;
