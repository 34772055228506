import React, { useEffect } from "react";

import type { OrchestratedRecognitionStep, RecognitionActivityType } from "@/types/activities/recognition";
import type { GuidString } from "@/types/utilityTypes";

import useAuthenticatedUser from "@hooks/useAuthenticatedUser";

import type { useActivitiesContext } from "@contexts/activities/ActivitiesContext";

import type { ActivePrompt, UserIdentity } from "@api/RecognitionActivityApiClient";
import type RecognitionCategory from "@core/enums/RecognitionCategory";
import RecognitionStepType from "@core/enums/RecognitionStepType";
import { trackActivityCompleted, trackActivityStarted } from "@core/tracking/track";

import RecognitionActivityCustomizeMessageStep from "./RecognitionActivityCustomizeMessageStep";
import RecognitionIntroStep from "./RecognitionIntroStep";
import RecognitionSelectPromptStep from "./RecognitionSelectPromptStep";

interface Props {
    className?: string;
    activityType: RecognitionActivityType;
    isFirstActivity: boolean;
    isLastActivity: boolean;
    correlationId: string;
    shuffledActivePrompts: ActivePrompt[];
    steps: OrchestratedRecognitionStep[];
    currentStepIndex: number;
    categoryId: RecognitionCategory;
    promptId: string;
    promptIndex?: number;
    onPromptSelected: (correlationId: string, categoryId: RecognitionCategory, promptId: string, promptIndex: number) => void;
    searchPeersAsync: (search: string) => Promise<UserIdentity[]>;
    onRecognitionLeft: ReturnType<typeof useActivitiesContext>["onRecognitionLeft"];
    onGoToPreviousStep: () => void;
    onGoToNextStep: () => void;
    onGoToNextActivity: () => void;
    onGoToOutro: () => void;
    onActivityEnded: (correlationId: string) => void;
    isIntroAnimated: boolean;
    onIntroAnimationCompleted: () => void;
}

const RecognitionActivity = ({
    className,
    activityType,
    isFirstActivity,
    isLastActivity,
    correlationId,
    shuffledActivePrompts,
    categoryId,
    promptId,
    promptIndex,
    steps,
    currentStepIndex,
    onPromptSelected,
    searchPeersAsync,
    onRecognitionLeft,
    onGoToPreviousStep,
    onGoToNextStep,
    onGoToNextActivity,
    onGoToOutro,
    onActivityEnded,
    isIntroAnimated,
    onIntroAnimationCompleted
}: Props) => {
    const { isNetworkAdmin, isCompanyManager, isTeamManager } = useAuthenticatedUser();

    const currentStep = steps[currentStepIndex];

    useEffect(() => {
        trackActivityStarted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [currentStep]);

    const handleOnRecognitionLeft = (recipientId: GuidString, message: string | null | undefined) => {
        onRecognitionLeft(correlationId, recipientId, categoryId, promptId, message);
    };

    const handleOnSelectPrompt = (selectedCategoryId: RecognitionCategory, selectedPromptId: string, selectedPromptIndex: number) => {
        onPromptSelected(correlationId, selectedCategoryId, selectedPromptId, selectedPromptIndex);
    };

    const handleOnActivityCompleted = () => {
        trackActivityCompleted(isNetworkAdmin, isCompanyManager, isTeamManager, activityType, isFirstActivity);
        onActivityEnded(correlationId);

        if (isLastActivity) {
            onGoToOutro();
        } else {
            onGoToNextActivity();
        }
    };

    const handleOnIntroCompleted = () => {
        onIntroAnimationCompleted();
        onGoToNextStep();
    };

    const renderCurrentStep = () => {
        const { stepType } = currentStep;

        switch (stepType) {
            case RecognitionStepType.Intro:
                return <RecognitionIntroStep onAnimationCompleted={handleOnIntroCompleted} />;
            case RecognitionStepType.SelectPrompt:
                return (
                    <RecognitionSelectPromptStep
                        {...currentStep}
                        correlationId={correlationId}
                        isIntroAnimated={isIntroAnimated}
                        onIntroAnimationCompleted={onIntroAnimationCompleted}
                        selectedPromptIndex={promptIndex}
                        shuffledActivePrompts={shuffledActivePrompts}
                        onSubmit={handleOnSelectPrompt}
                        onAnimationCompleted={onGoToNextStep}
                    />
                );
            case RecognitionStepType.CustomizeMessage:
            {
                const selectedPrompt = shuffledActivePrompts.find(x => x.id === promptId);

                return (
                    <RecognitionActivityCustomizeMessageStep
                        {...currentStep}
                        correlationId={correlationId}
                        selectedPrompt={selectedPrompt!}
                        searchPeersAsync={searchPeersAsync}
                        onSubmit={handleOnRecognitionLeft}
                        onCancel={onGoToPreviousStep}
                        onAnimationCompleted={handleOnActivityCompleted}
                    />
                );
            }
            default:
                throw new Error(`Recognition Step Type (${stepType}) not supported`);
        }
    };

    return (
        <div className={className}>
            {renderCurrentStep()}
        </div>
    );
};

export default RecognitionActivity;